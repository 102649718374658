<script>
import { onMounted, toRefs, reactive } from 'vue'
import { useStore } from 'vuex'
import { isNameGibberish } from '@/util/validations'
import { PATTERN_BLACK_LIST } from '@/util/bad_words'

const stepName = 'Name'

export default {
  name: stepName,

  setup(_, {emit}) {
    const store = useStore()

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const state = reactive({
      firstName: {
        value: '',
        error: false
      },
      lastName: {
        value: '',
        error: false
      }
    })

    const firstNameHandler = () => {
      state.firstName.error = false
    }

    const lastNameHandler = () => {
      state.lastName.error = false
    }

    const validateName = () => {
      state.firstName.error = state.firstName.value.length === 0
      state.lastName.error = state.lastName.value.length === 0
    }

    const setAnswer = () => {
      validateName()
      if (state.firstName.error || state.lastName.error) return

      //name gibberish
      let nameGibberish = isNameGibberish(state.firstName.value) ? 'yes' : 'no'
      if (nameGibberish === 'no') {
        nameGibberish = isNameGibberish(state.lastName.value) ? 'yes' : 'no'
      }

      //name bad word
      let nameBadWord = PATTERN_BLACK_LIST.test(state.firstName.value) ? 'yes' : 'no'
      if (nameBadWord === 'no') {
        nameBadWord = PATTERN_BLACK_LIST.test(state.lastName.value) ? 'yes' : 'no'
      }

      const answers = [
        {field: 'first_name', value: state.firstName.value},
        {field: 'last_name', value: state.lastName.value},
        {field: 'name_gibberish', value: nameGibberish},
        {field: 'name_bad_word', value: nameBadWord}
      ]

      store.commit('SET_FIELDS', answers)
      emit('next-step', stepName)
    }

    return {
      firstNameHandler,
      lastNameHandler,
      setAnswer,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div>
    <div class="row input-container">
      <div class="box">
        <input
          class="form-input name-input"
          :class="{'name-error' : firstName.error}"
          type="text"
          placeholder="First Name"
          v-model="firstName.value"
          @input="firstNameHandler"
        >
      </div>
      <div class="box">
        <input
          class="form-input name-input"
          :class="{'name-error' : lastName.error}"
          type="text"
          placeholder="Last Name"
          v-model="lastName.value"
          @input="lastNameHandler"
        >
      </div>
    </div>

    <div class="row">
      <div class="box">
        <button
          class="main-button main-button-w mt-lg"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/name.scss';
</style>